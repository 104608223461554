var projectSettings = {
	slider: {
		speed: 6500,
		animationSpeed: 1500,
		arrows: true,
		pagination: true,
		paginationArrows: true
	},
	sliderFullHeightScrollButton: false,
	inlineSVG: [
		'.header__open-off-canvas img',
		'.header__call img',
		'.off-canvas__call img',
		'.footer__social img'
	],
	mapStyle: [{featureType:"landscape",elementType:"geometry.fill",stylers:[{color:"#b9bbc5"}]},{featureType:"poi",elementType:"geometry.fill",stylers:[{color:"#aa8a7e"}]},{featureType:"poi",elementType:"labels.text",stylers:[{visibility:"simplified"}]},{featureType:"poi",elementType:"labels.text.fill",stylers:[{color:"#ffffff"},{visibility:"off"}]},{featureType:"poi",elementType:"labels.text.stroke",stylers:[{weight:"1.67"},{hue:"#ff0000"}]},{featureType:"poi",elementType:"labels.icon",stylers:[{visibility:"off"}]},{featureType:"road",elementType:"geometry.fill",stylers:[{color:"#ffffff"}]},{featureType:"road",elementType:"geometry.stroke",stylers:[{gamma:7.18}]},{featureType:"road",elementType:"labels.icon",stylers:[{visibility:"off"}]},{featureType:"road.local",elementType:"labels.text",stylers:[{visibility:"simplified"}]},{featureType:"transit.line",elementType:"geometry",stylers:[{gamma:.48}]},{featureType:"transit.station",elementType:"labels.icon",stylers:[{visibility:"off"}]},{featureType:"water",elementType:"all",stylers:[{color:"#deedf3"}]},{featureType:"water",elementType:"labels.text.fill",stylers:[{color:"#ffffff"}]}],
	accordion: {
		showFirstItem: false
	}
};