$(document).ready(function () {
	customHome();
	customSpaces();
	customLocation();
	customGallery();
	customEventsDetail();
});


// Override 
initAnimateInView = function () {
	$(window).on('DOMContentLoaded load resize scroll', animateElementsInView);
	animateElementsInView();
	
	function animateElementsInView() {
		$('[data-animate-in-view]').each(function () {
			if ( isElementInViewport($(this)) ) {
				$(this).addClass( $(this).data('animate-in-view') );
			}
		});
	}
	
	// https://stackoverflow.com/questions/123999/how-to-tell-if-a-dom-element-is-visible-in-the-current-viewport
	function isElementInViewport (el) {
		//special bonus for those using jQuery
		if (typeof jQuery === "function" && el instanceof jQuery) {
			el = el[0];
		}
	
		var rect = el.getBoundingClientRect();
	
		var t = rect.y;
		var b = window.innerHeight - rect.y - rect.height;
		var h = window.innerHeight;
	
		if (
			( (t >= 200) && (t <= h) ) ||
			( (b >= 200) && (b <= h) )
		) {
			return true;
		} else {
			return false;
		}
	}
}

// Override
initHeader = function () {
	
	function throttle(fn, threshhold, scope) {
	  threshhold || (threshhold = 250);
	  var last,
		  deferTimer;
	  return function () {
		var context = scope || this;
	
		var now = +new Date,
			args = arguments;
		if (last && now < last + threshhold) {
		  // hold on to it
		  clearTimeout(deferTimer);
		  deferTimer = setTimeout(function () {
			last = now;
			fn.apply(context, args);
		  }, threshhold);
		} else {
		  last = now;
		  fn.apply(context, args);
		}
	  };
	}

	// Detect if the header is over and image slider, if yes then give it a transparent background
	if ( $('.slider--full-height, .directions').length > 0 ) {
		setHeaderTransparency();
		$(window).on('scroll', setHeaderTransparency);
	}
	
	if ( $('.slider').length === 0 ) {
		$('body').addClass('no-slider');
	}
	
	function setHeaderTransparency() {
		if (window.pageYOffset < 150) {	
			if ( $('.slider').length > 0 ) {
				$('.header').addClass('header--transparent');
			}
		} else {	
			if ( $('.slider').length > 0 ) {
				$('.header').removeClass('header--transparent');
			}
		}
	}
	
	setHeaderDepth();
	$(window).on('scroll', setHeaderDepth);
	
	function setHeaderDepth() {
		if (window.pageYOffset < 150) {
			$('.header').removeClass('header--compact');
		} else {
			$('.header').addClass('header--compact');
		}
	}
}



function customHome() {
	$('.home__slider-inner').slick({
		arrows: true,
		fade: true
	});
}




function customSpaces() {
	$('.spaces__slider-inner').slick({
		fade: true,
		arrows: true
	});
}

// Override
initDetail = function () {
	$('.detail__slider-inner').slick({
		fade: true,
		arrows: true
	});
};


// Override
initOtherRooms = function () {
	// Do nothing!
};


function customLocation() {
	$('.map__carousel-inner').slick({
		slidesToShow: 3,
		arrows: false,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 640,
				settings: {
					slidesToShow: 1,
					arrows: true
				}
			}
		]
	})
}


function customGallery() {
	$('.filter-controls-dropdown select').selectric();
}


// Override
initGallery = function () {
	$('.gallery__item a').on('click', function () {
		var $clickedThumbnail = $(this);
		
		
		
		// Dynamically load the gallery slider into a reveal popup	
		var modal = '<div id="gallery__modal" class="gallery__modal full reveal loading" data-reveal><div class="gallery__modal-image"><button class="gallery__modal-close" data-close aria-label="Close modal" type="button"><span aria-hidden="true">Close</span></button><img src="{{src}}"><button class="gallery__modal-prev">Previous</button><button class="gallery__modal-next">Next</button></div><div class="gallery__modal-content grid-x"><div class="gallery__modal-caption cell small-12 medium-6">{{caption}}</div><div class="gallery__modal-cta cell small-12 medium-6">{{cta}}</div></div></div>';
		
		modal = modal.replace('{{src}}', $(this).attr('href'));
		modal = modal.replace('{{caption}}', $(this).data('title') || '');
		
		var cta = $(this).data('description') || '';
		if ( ( typeof $(this).data('book-link') !== 'undefined' ) && ( $(this).data('book-link') !== '' ) ) {
			cta += '<a href="' + $(this).data('book-link') + '" class="button" target="_blank">' + ($(this).data('book-title') || 'Book Now') + '</a>';
		}
		modal = modal.replace('{{cta}}', cta);
		
		var $modal = modal;
		
		$('body').append($modal);
		
		$('#gallery__modal').foundation().on('closed.zf.reveal', function () {
			$('#gallery__modal').remove();
		});
		
		$('#gallery__modal').foundation('open');
		
		$('.gallery__modal-next').on('click', function () {
			$('#gallery__modal').foundation('close');
			$('#gallery__modal').remove();
			if ( $clickedThumbnail.parent().next(':visible').length > 0 ) { // is the next thumb visible?
				$clickedThumbnail.parent().next(':visible').find('a').trigger('click');
			} else if ( $clickedThumbnail.parent().nextUntil(':visible').length > 0 ) { // no, so find the next visible one
				$clickedThumbnail.parent().nextAll(':visible').first().find('a').trigger('click');
				$('#gallery__modal').foundation('close');
				$('#gallery__modal').remove();
			}
		});
		
		$('.gallery__modal-prev').on('click', function () {
			$('#gallery__modal').foundation('close');
			$('#gallery__modal').remove();
			if ( $clickedThumbnail.parent().prev(':visible').length > 0 ) { // is the previous thumb visible?
				$clickedThumbnail.parent().prev(':visible').find('a').trigger('click');
			} else if ( $clickedThumbnail.parent().prevUntil(':visible').length > 0 ) { // no, so find the next visible one that comes before this
				$clickedThumbnail.parent().prevAll(':visible').last().find('a').trigger('click');
				$('#gallery__modal').foundation('close');
				$('#gallery__modal').remove();
			}
		}); 
		
		return false;
	});
	
	refreshMasonry();
	$('.gallery').on('filter-changed', function () {
		setTimeout(refreshMasonry, 600);
	});
	
	Foundation.onImagesLoaded($('.gallery__item img'), refreshMasonry);
	
	function refreshMasonry() {
		$('.gallery__grid').masonry({
			columnWidth: '.gallery__item-sizer',
			itemSelector: '.gallery__item',
			percentPosition: true,
			horizontalOrder: true
		});
	};
	
	// Remove the transition class after loading to avoid jumpy thumbnails
	setTimeout(function () {
		$('.gallery__item').removeClass('transition--2000');
	}, 2000);
	
	
	
	// Remove empty filter buttons (if not handled by the plugin)
	$('.filter__buttons button').each(function () {
		if ( $(this).text() == '' ) {
			$(this).remove();
		}
	});
}



function customEventsDetail() {
	$('.event-detail__slider-inner').slick({
		fade: true,
		arrows: true
	});
}


// Override 
initOffCanvas = function () {
	$('.off-canvas__nav-primary > ul > li').each(function () {
		// Automatically add the "Overview" links to the landing page
		if ( $(this).find('ul').length > 0 ) {
			var overviewLink = $(this).find('a').first().attr('href');
			$(this).find('ul').prepend('<li class="offcanvas__overview-link"><a href="' + overviewLink + '">Overview</a></li>');
		}
	});
	
	
	$('.off-canvas__nav-primary > ul > li > a').on('click', function () {
		if ( $(this).next('.off-canvas__nav-sub').length > 0 ) { // only trigger if there are sub pages to show
			if ( $(this).parent().hasClass('open') ) {
				// second click of main item closes
				$('.off-canvas__nav-primary > ul > li.open').removeClass('open').find('.off-canvas__nav-sub').slideUp();
			} else {
				// close any other open items
				$('.off-canvas__nav-primary > ul > li.open').removeClass('open').find('.off-canvas__nav-sub').slideUp();
				
				// and open the new one
				$(this).parent().addClass('open');
				$(this).next().slideDown();
			}
			return false; // prevent navigating
		}
	});
}